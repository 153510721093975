import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getSettlements } from "../redux/actions/settlementsACtions";
import { useNavigate } from "react-router-dom";

/* ID(pin):16385
DocNum(pin):17925
Debit(pin):"85.000"
Ciradet(pin):".000"
Note(pin):"تسوية مطالبة رقم17925 باسم حاتم الحسنوني"
Kind(pin):14
UserID(pin):403 */

const columns = [
  {
    field: "docNumber",
    headerName: "رقم المستند",
    width: 200,
  },
  {
    field: "date",
    headerName: "التاريخ",
    width: 200,
  },
  {
    field: "description",
    headerName: "البيان",
    type: "text",
    width: 300,
  },
  {
    field: "amount",
    headerName: "المبلغ",
    width: 200,
  },
  {
    field: "paid",
    headerName: "المدفوع",
    width: 200,
  },
  // {
  //   field: "balance",
  //   headerName: "الرصيد",
  //   width: 200,
  // },
];

const SettlementsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.userLogin);
  const { loadingSettlements, settlements } = useSelector(
    (state) => state.settlements
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      settlements.map((row) => {
        return {
          id: row.ID,
          docNumber: row.DocNum,
          date: new Date(row.MDate.date).toLocaleDateString("en-GB"),
          description: row.Note,
          amount: row.Debit,
          paid: row.Ciradet,
        };
      })
    );
  }, [settlements]);

  useEffect(() => {
    dispatch(getSettlements(loginInfo.BranchID));
  }, []);

  return (
    <div className="settlements-page">
      {loadingSettlements ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <Box className="settlements-grid">
          <DataGrid
            style={{ backgroundColor: "var(--background-color)" }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
            pageSizeOptions={[50]}
            disableRowSelectionOnClick
          />
        </Box>
      )}
    </div>
  );
};

export default SettlementsPage;
