import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
  Box,
  Fab,
  CircularProgress,
  TextField,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomDatePicker from "../components/CustomDatePicker";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

// actions
import {
  changeStoreOrderSearchType,
  getStoreOrdersAll,
  getStoreOrdersByDate,
  getStoreOrdersByType,
  searchStoreOrdersByText,
} from "../redux/actions/orderActions";
import { CONFIRM_ADD_STORE_ORDER_MESSAGE } from "../redux/constants/orderConstants";
import StoreOrderSavedMessage from "../components/dialogs/StoreOrderSavedMessage";

const columns = [
  {
    field: "orderNumber",
    headerName: "رقم الطلب",
    width: 150,
  },
  {
    field: "date",
    headerName: "التاريخ",
    width: 200,
  },
  {
    field: "customerName",
    headerName: "اسم العميل",
    width: 250,
  },
  {
    field: "state",
    headerName: "الحالة",
    width: 150,
  },
  {
    field: "phone1",
    headerName: "الهاتف 1",
    width: 150,
  },
  {
    field: "phone2",
    headerName: "الهاتف 2",
    width: 150,
  },
  {
    field: "address",
    headerName: "العنوان",
    width: 200,
  },
  {
    field: "city",
    headerName: "المدينة",
    width: 200,
  },
  {
    field: "returnState",
    headerName: "الإرجاع",
    width: 200,
  },
  {
    field: "reason",
    headerName: "السبب",
    width: 200,
  },
];

const StoreOrderPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.userLogin);
  const {
    loadingStoreOrders,
    errMsgStoreOrders,
    storeOrders,
    type,
    totalRecords,
  } = useSelector((state) => state.storeOrders);

  const { dateFrom, dateTo } = useSelector(
    (state) => state.storeOrdersSearchDate
  );

  const [rows, setRows] = useState([]);
  const [localDateFrom, setLocalDateFrom] = useState(dateFrom);
  const [localDateTo, setLocalDateTo] = useState(dateTo);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 50,
    rowCount: totalRecords || 0,
  });
  const [isDateSearching, setIsDateSearching] = useState(false);

  const handleSearch = () => {
    setIsDateSearching(true);
    dispatch(changeStoreOrderSearchType("date"));
    dispatch(
      getStoreOrdersByDate(loginInfo.BranchID, localDateFrom, localDateTo)
    );
  };

  const handleSelectStoreOrder = (e) => {
    navigate(`/store-order-details/${e.row.id}`);
  };

  const handlePaginationChange = (e) => {
    if (isDateSearching) return;
    const page = e.page;

    switch (type) {
      case "all":
        dispatch(getStoreOrdersAll(loginInfo.BranchID, page));
        break;
      case "today":
        dispatch(getStoreOrdersByType(loginInfo.BranchID, 0, true, page));
        break;
      case "delivered":
        dispatch(getStoreOrdersByType(loginInfo.BranchID, 4, false, page));
        break;
      case "proccessing":
        dispatch(getStoreOrdersByType(loginInfo.BranchID, 1, false, page));
        break;
      case "done":
        dispatch(getStoreOrdersByType(loginInfo.BranchID, 5, false, page));
        break;
      case "shipping":
        dispatch(getStoreOrdersByType(loginInfo.BranchID, 3, false, page));
        break;
      case "returned":
        dispatch(getStoreOrdersByType(loginInfo.BranchID, 10, false, page));
        break;
      case "returnedBack":
        dispatch(getStoreOrdersByType(loginInfo.BranchID, 7, false, page));
        break;
    }
    // Update the pagination state
    setPagination(e);
  };

  useEffect(() => {
    storeOrders
      ? setRows(
          storeOrders.map((row) => {
            return {
              id: row.ID,
              orderNumber: row.ID,
              date: new Date(row.ShDate.date).toLocaleDateString("en-GB"),
              customerName: row.CoName,
              state: row.ScName,
              phone1: row.Tel1,
              phone2: row.Tel2,
              address: row.Adress,
              city: row.CName,
              returnState: row.ReName,
              reason: row.ResText,
            };
          })
        )
      : setRows([]);
  }, [storeOrders]);

  useEffect(() => {
    !isDateSearching && handlePaginationChange(pagination);

    return () => {
      dispatch({ type: CONFIRM_ADD_STORE_ORDER_MESSAGE });
    };
  }, []);

  return (
    <div className="orders-page">
      <StoreOrderSavedMessage />
      <Row className="orders-page-tool-bar">
        <Col sm={12} md={2} style={{ padding: "10px" }}>
          <div className="orders-tool-bar-buttons-container">
            <Fab
              variant="contained"
              style={{
                zIndex: "0",
                backgroundColor: "var(--primary-color)",
                color: "#fff",
              }}
              onClick={() => navigate("/store-order-details")}
            >
              <AddCircleIcon />
            </Fab>
            <Fab
              variant="contained"
              style={{
                zIndex: "0",
                backgroundColor: "var(--primary-color)",
                color: "#fff",
              }}
              onClick={handleSearch}
            >
              <SearchIcon />
            </Fab>
          </div>
        </Col>

        <Col sm={12} md={4} style={{ padding: "10px" }}>
          <Row>
            <Col>
              <div
                className="orders-tool-bar-buttons-container"
                style={{ flexWrap: "wrap" }}
              >
                <div className="orders-tool-bar-dtp-container">
                  <CustomDatePicker
                    label={"من"}
                    value={localDateFrom}
                    setValue={setLocalDateFrom}
                  />
                  <CustomDatePicker
                    label={"إلى"}
                    value={localDateTo}
                    setValue={setLocalDateTo}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6} style={{ padding: "10px", display: "flex" }}>
          <TextField
            fullWidth
            autoComplete="off"
            style={{ maxWidth: "400px", margin: "auto" }}
            label="بحث"
            placeholder="رقم الطلبية أو الهاتف أو اسم المستلم"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  {searchText && (
                    <IconButton
                      onClick={() => setSearchText("")}
                      style={{ color: "var(--primary-color)" }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      searchText &&
                        dispatch(
                          searchStoreOrdersByText(
                            loginInfo.BranchID,
                            searchText
                          )
                        );
                    }}
                    edge="end"
                    style={{ color: "var(--primary-color)" }}
                  >
                    <SearchIcon />
                  </IconButton>
                </>
              ),
            }}
          />
        </Col>
      </Row>
      {loadingStoreOrders ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <Box sx={{ width: "100%", height: "500px" }}>
          <DataGrid
            style={{ backgroundColor: "var(--background-color)" }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: !isDateSearching
                  ? pagination
                  : { pageSize: 50 },
              },
            }}
            paginationMode={!isDateSearching ? "server" : "client"}
            onPaginationModelChange={
              !isDateSearching ? handlePaginationChange : () => {}
            }
            pageSizeOptions={[50]}
            disableRowSelectionOnClick
            onRowClick={handleSelectStoreOrder}
            rowCount={totalRecords}
          />
        </Box>
      )}
    </div>
  );
};

export default StoreOrderPage;
