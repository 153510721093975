import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getInventory } from "../redux/actions/InventoryActions";

const columns = [
  {
    field: "number",
    headerName: "الرقم",
    width: 200,
  },
  {
    field: "name",
    headerName: "الإسم",
    width: 300,
  },

  {
    field: "balance",
    headerName: "الرصيد",
    width: 200,
  },
];

const InventoryPage = () => {
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.userLogin);
  const { loadingInventory, inventory } = useSelector(
    (state) => state.inventory
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      inventory
        .filter((row) => row.Balance > 0)
        .map((row) => ({
          id: row.ID,
          number: row.ID,
          name: row.Name,
          balance: row.Balance,
        }))
    );
  }, [inventory]);

  useEffect(() => {
    dispatch(getInventory(loginInfo.BranchID));
  }, []);

  return (
    <div className="p-4 vh-100 d-flex">
      {loadingInventory ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <Box className="settlements-grid">
          <DataGrid
            style={{ backgroundColor: "var(--background-color)" }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
            pageSizeOptions={[50]}
            disableRowSelectionOnClick
          />
        </Box>
      )}
    </div>
  );
};

export default InventoryPage;
