import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import storeRoutes from "./routes/storeRoutes";
import deliveryRoutes from "./routes/deliveryRoutes";

function App() {
  const { loginInfo } = useSelector((state) => state.userLogin);
  const routes = loginInfo
    ? loginInfo.stats == 1
      ? storeRoutes
      : deliveryRoutes
    : storeRoutes;
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          if ((route.isPrivate === true) & (loginInfo === null))
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to={"/login"} />}
              />
            );
          else if ((route.mustLogout === true) & (loginInfo !== null))
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to={"/"} />}
              />
            );
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
