import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
  TextField,
  Button,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { getCities, getPlaces } from "../redux/actions/cityActions";
import CustomDatePicker from "../components/CustomDatePicker";
import StoreOrderDetailsGridView from "../components/grid-view/StoreOrderDetailsGridView";
import {
  addStoreOrder,
  getStorages,
  updateOrder,
} from "../redux/actions/orderActions";
import { getItems } from "../redux/actions/itemActions";

const StoreOrderDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { loginInfo } = useSelector((state) => state.userLogin);

  const { loadingCities, cities } = useSelector((state) => state.cities);

  const { loadingPlaces, places } = useSelector((state) => state.places);

  const { storeOrders } = useSelector((state) => state.storeOrders);

  const { storages } = useSelector((state) => state.storages);

  const { items } = useSelector((state) => state.items);

  // state
  const [customerName, setCustomerName] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [city, setCity] = useState(null);
  const [place, setPlace] = useState(null);
  const [storage, setStorage] = useState(null);
  const [item, setItem] = useState(null);
  const [address, setAddress] = useState("");
  const [date, setDate] = useState(Date.now());
  const [description, setDescription] = useState("");
  const [transaction, setTransaction] = useState("0");
  const [deliveryPrice, setDeliveryPrice] = useState("0");
  const [itemQuantity, setItemQuantity] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [balance, setBalance] = useState("");
  const [total, setTotal] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [shippmentPrice, setShippmentPrice] = useState("");

  // Errors state
  const [itemErr, setItemErr] = useState("");
  const [itemQuantityErr, setItemQuantityErr] = useState("");
  const [itemPriceErr, setItemPriceErr] = useState("");
  const [storageErr, setStorageErr] = useState("");

  const [preventUpdate, setPreventUpdate] = useState(false);

  const handlePhone1Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 10); // Limit to maximum length (adjust as needed)
    setPhone1(maxLengthValue);
  };

  const handlePhone2Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 10); // Limit to maximum length (adjust as needed)
    setPhone2(maxLengthValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (preventUpdate) return;

    const amount = orderItems.reduce(
      (accumulator, i) => accumulator + i.Total,
      0
    );

    const count = orderItems.reduce(
      (accumulator, i) => accumulator + i.outcome,
      0
    );

    const shDate = new Date(date);
    const shDateStrnig = `${shDate.getFullYear()}-${String(
      shDate.getMonth() + 1
    ).padStart(2, "0")}-${String(shDate.getDate()).padStart(
      2,
      "0"
    )} 00:00:00.000`;

    if (id) {
      const newShippment = {
        ID: id,
        CoName: customerName,
        Tel1: phone1,
        Tel2: phone2,
        Adress: address,
        CitiesID: city.ID,
        HalaID: deliveryPrice.toString(),
        RemoneID: transaction.toString(),
        wasf: description,
        Count: count,
        Bar1: place.ID.toString(),
        ShDate: shDateStrnig,
        Amount: amount,
        IDStory: storage.ynum,
        StorID: loginInfo.BranchID,
        Amount: amount,
        ItemsData: JSON.stringify(
          orderItems.map((item) => {
            return {
              ItemNum: item.id,
              price: item.price,
              outcome: item.outcome,
              Total: item.Total,
              IDate: item.IDate,
              IDStor: item.IDStor,
              IDStory: item.IDStory,
            };
          })
        ),
      };
      dispatch(updateOrder(newShippment));
      navigate(-1);
    } else {
      const newShippment = {
        CoName: customerName,
        Tel1: phone1,
        Tel2: phone2,
        Adress: address,
        CitieID: city.ID,
        StorID: loginInfo.BranchID,
        HalaID: deliveryPrice.toString(),
        RemoneID: transaction.toString(),
        wasf: description,
        Count: count,
        ShDate: shDateStrnig,
        TimeIn: new Date().toLocaleTimeString("en-US", {
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        Amount: amount,
        Bar1: place.ID,
        UserID: loginInfo.SerUser,
        IDStory: storage.ynum,
        ItemsData: JSON.stringify(
          orderItems.map((item) => {
            return {
              ItemNum: item.id,
              price: item.price,
              outcome: item.outcome,
              Total: item.Total,
              IDate: item.IDate,
              IDStor: item.IDStor,
              IDStory: item.IDStory,
            };
          })
        ),
      };
      dispatch(addStoreOrder(newShippment));
    }
    navigate("/store-order");
  };

  const handleAddItem = () => {
    item ? setItemErr("") : setItemErr("يرجى إختيار الصنف");
    itemQuantity
      ? setItemQuantityErr("")
      : setItemQuantityErr("يرجى إدخال الكمية");
    itemPrice ? setItemPriceErr("") : setItemPriceErr("يرجى إدخال السعر");
    storage
      ? setStorageErr("")
      : setStorageErr("يرجى إختيار المساحة التخزينية");

    if (
      (item !== null) &
      (itemQuantity !== "") &
      (itemPrice !== "") &
      (storage !== null)
    ) {
      const itemUsedQuantity = orderItems.find((i) => i.id == item.ID);
      const itemMaxQuantity = item.Balance;
      if (
        (itemUsedQuantity &&
          itemQuantity <= itemMaxQuantity - itemUsedQuantity.outcome) ||
        (!itemUsedQuantity && itemQuantity <= itemMaxQuantity)
      ) {
        const shDate = new Date(date);
        const shDateStrnig = `${shDate.getFullYear()}-${String(
          shDate.getMonth() + 1
        ).padStart(2, "0")}-${String(shDate.getDate()).padStart(
          2,
          "0"
        )} 00:00:00.000`;

        const newItem = {
          id: item.ID,
          itemNum: item.ID,
          itemName: item.Name,
          price: itemPrice,
          outcome: itemQuantity,
          Total: itemQuantity * itemPrice,
          IDate: shDateStrnig,
          IDStor: loginInfo.BranchID,
          IDStory: storage.ynum,
        };

        // Check if the item already exists in the orderItems list
        const existingItemIndex = orderItems.findIndex(
          (orderItem) => orderItem.itemNum == newItem.itemNum
        );

        if (existingItemIndex !== -1) {
          // Item already exists, update the outcome (quantity) and total
          const updatedOrderItems = [...orderItems];
          let outcome = parseInt(updatedOrderItems[existingItemIndex].outcome);
          outcome += parseInt(itemQuantity);
          updatedOrderItems[existingItemIndex].outcome = outcome;
          updatedOrderItems[existingItemIndex].price = itemPrice;
          updatedOrderItems[existingItemIndex].Total = outcome * itemPrice;
          setOrderItems(updatedOrderItems);
        } else {
          // Item doesn't exist, add it to the orderItems list
          setOrderItems([...orderItems, newItem]);
        }
      } else {
        setItemQuantityErr("الكمية لا تكفي");
      }
    }
  };

  const handleItemSelected = (e, newValue) => {
    setItem(newValue);
    setItemErr("");
    setItemPrice(newValue.Price);
    setBalance(newValue.Balance);
  };

  const handleEmptyItems = () => {
    setOrderItems([]);
  };

  const handleClearData = () => {
    setCustomerName("");
    setPhone1("");
    setPhone2("");
    setAddress("");
    setDescription("");
    setTransaction(0);
    setDeliveryPrice(0);
    setPlace(null);
    setCity(null);
    setItem(null);
    setStorage(null);
    setDate(Date.now());
    setOrderItems([]);
    setPreventUpdate(false);
  };

  useEffect(() => {
    let price = itemPrice ? itemPrice : 0;
    let quantity = itemQuantity ? itemQuantity : 0;
    let newTotal = price * quantity == 0 ? "" : price * quantity;
    setTotal(newTotal);
  }, [itemPrice, itemQuantity]);

  useEffect(() => {
    if ((cities.length > 0) & (places.length > 0) & (storages.length > 0))
      if (id) {
        const storeOrder = storeOrders.find((item) => item.ID == id);
        if (storeOrder) {
          setCustomerName(storeOrder.CoName);
          setPhone1(storeOrder.Tel1);
          setPhone2(storeOrder.Tel2);
          setAddress(storeOrder.Adress);
          setCity(cities.find((city) => city.CName === storeOrder.CName));
          setPlace(places.find((place) => place.ID === storeOrder.Bar1));
          setStorage(storages.find((storage) => storeOrder.IDStory));
          setDate(storeOrder.ShDate.date);
          setTransaction(storeOrder.RemoneID);
          setDeliveryPrice(storeOrder.HalaID);
          setDescription(storeOrder.wasf);
          setOrderItems(
            storeOrder.Item.map((item, index) => {
              return {
                id: ++index,
                itemNum: index,
                itemName: item.Name,
                outcome: item.outcome,
                price: item.Pric,
                Total: item.Pric * item.outcome,
              };
            })
          );
          storeOrder && storeOrder.ShCase !== 1
            ? setPreventUpdate(true)
            : setPreventUpdate(false);
        } else navigate("/store-order-details");
      } else handleClearData();
  }, [id, cities, places, storages]);

  useEffect(() => {
    const total = parseFloat(
      orderItems.reduce(
        (accumulator, item) =>
          accumulator +
          parseFloat(item.price).toLocaleString("en-GB") *
            parseFloat(item.outcome).toLocaleString("en-GB"),
        0
      )
    ).toLocaleString("en-GB");

    setShippmentPrice(total);
  }, [orderItems]);

  useEffect(() => {
    dispatch(getCities());
    dispatch(getPlaces());
    dispatch(getStorages());
    dispatch(getItems(loginInfo.BranchID));

    return () => {
      handleClearData();
    };
  }, []);

  return (
    <form onSubmit={handleSubmit} className="store-order-details-page">
      {loadingCities | loadingPlaces ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <>
          <Row style={{ margin: "10px auto" }}>
            <Col
              sm={12}
              lg={id ? 6 : 4}
              style={{ marginBottom: "30px", height: "100%" }}
            >
              <div className="order-details-data-container">
                <h4>بيانات الزبون</h4>
                <div className="order-details-customer">
                  <Row>
                    <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                      <TextField
                        fullWidth
                        required
                        autoComplete="off"
                        label="اسم المستلم"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        disabled={preventUpdate}
                      />
                    </Col>
                    <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                      <TextField
                        required
                        fullWidth
                        autoComplete="off"
                        label="رقم الهاتف 1"
                        placeholder="092xxxxxxx"
                        type="text"
                        disabled={preventUpdate}
                        value={phone1}
                        onChange={handlePhone1Change}
                      />
                    </Col>
                    <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                      <TextField
                        required
                        fullWidth
                        autoComplete="off"
                        label="العنوان"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        disabled={preventUpdate}
                      />
                    </Col>
                    <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        label="رقم الهاتف 2"
                        type="text"
                        placeholder="092xxxxxxx"
                        value={phone2}
                        onChange={handlePhone2Change}
                        disabled={preventUpdate}
                      />
                    </Col>
                    <Col sm={12} md={12} style={{ marginBottom: "10px" }}>
                      <Autocomplete
                        disablePortal
                        options={cities}
                        getOptionLabel={(option) => option.CName}
                        value={city}
                        onChange={(e, newValue) => setCity(newValue)}
                        disabled={preventUpdate}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth
                            {...params}
                            label="المدينة"
                          />
                        )}
                      />
                    </Col>
                    <Col sm={12} md={12}>
                      <CustomDatePicker
                        label={"تاريخ الشحنة"}
                        style={{ width: "100%" }}
                        containerStyle={{ width: "100%" }}
                        value={date}
                        setValue={setDate}
                        disabled={preventUpdate}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col
              sm={12}
              lg={id ? 6 : 4}
              style={{ marginBottom: "30px", height: "100%" }}
            >
              <div className="order-details-data-container">
                <h4>بيانات الشحنة</h4>
                <div className="order-details-customer">
                  <Row>
                    <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                      <Autocomplete
                        disablePortal
                        options={places}
                        getOptionLabel={(option) => option.BName}
                        value={place}
                        onChange={(e, newValue) => setPlace(newValue)}
                        disabled={preventUpdate}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth
                            {...params}
                            label="مكان الإستلام"
                          />
                        )}
                      />
                    </Col>
                    <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                      <Autocomplete
                        disablePortal
                        options={storages}
                        getOptionLabel={(option) => option.yname}
                        value={storage}
                        onChange={(e, newValue) => {
                          setStorage(newValue);
                          setStorageErr("");
                        }}
                        disabled={preventUpdate}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth
                            {...params}
                            label="المساحات التخزينية"
                            error={storageErr ? true : false}
                            helperText={storageErr}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={12} md={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        label="قيمة الشحنة"
                        value={shippmentPrice}
                        disabled
                      />
                    </Col>
                    <Col sm={12} md={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        label="وصف الشحنة"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        disabled={preventUpdate}
                      />
                    </Col>
                    <Col
                      sm={12}
                      md={12}
                      style={{ marginBottom: "10px", textAlign: "center" }}
                    >
                      <FormControl style={{ marginBottom: "20px" }}>
                        <FormLabel style={{ textAlign: "start" }}>
                          سداد قيمة التوصيل
                        </FormLabel>
                        <RadioGroup
                          row
                          name="delivery-groub"
                          value={deliveryPrice}
                          onChange={(e) => setDeliveryPrice(e.target.value)}
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio disabled={preventUpdate} />}
                            label="المستلم"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio disabled={preventUpdate} />}
                            label="المرسل"
                          />
                        </RadioGroup>
                      </FormControl>

                      <FormControl>
                        <FormLabel style={{ textAlign: "start" }}>
                          سداد قيمة الحوالة
                        </FormLabel>
                        <RadioGroup
                          row
                          name="transaction-groub"
                          value={transaction}
                          onChange={(e) => setTransaction(e.target.value)}
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio disabled={preventUpdate} />}
                            label="المستلم"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio disabled={preventUpdate} />}
                            label="المرسل"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {!id && (
              <Col sm={12} lg={4}>
                <div className="order-details-data-container">
                  <h4>بيانات الصنف</h4>
                  <div className="order-details-customer">
                    <Row>
                      <Col sm={12} md={12} style={{ marginBottom: "10px" }}>
                        <Autocomplete
                          disablePortal
                          options={items.filter((i) => i.Balance > 0)}
                          getOptionLabel={(option) => option.Name}
                          value={item}
                          onChange={handleItemSelected}
                          disabled={preventUpdate}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              label="اسم الصنف"
                              error={itemErr ? true : false}
                              helperText={itemErr}
                            />
                          )}
                        />
                      </Col>
                      <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                        <TextField
                          fullWidth
                          type="number"
                          autoComplete="off"
                          label="الكمية"
                          value={itemQuantity}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if ((inputValue !== "") & (inputValue < 1)) {
                              setItemQuantityErr(
                                "يجب أن تكون الكمية أكبر من أو تساوي 1"
                              );

                              setTimeout(() => {
                                setItemQuantityErr("");
                              }, 3000);
                            } else {
                              setItemQuantity(inputValue);
                              setItemQuantityErr("");
                            }
                          }}
                          error={itemQuantityErr ? true : false}
                          helperText={itemQuantityErr}
                          disabled={preventUpdate}
                        />
                      </Col>
                      <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                        <TextField
                          fullWidth
                          type="number"
                          autoComplete="off"
                          label="السعر"
                          value={itemPrice}
                          onChange={(e) => {
                            setItemPrice(e.target.value);
                            setItemPriceErr("");
                          }}
                          error={itemPriceErr ? true : false}
                          helperText={itemPriceErr}
                          disabled={preventUpdate}
                        />
                      </Col>
                      <Col sm={12} md={6} style={{ marginBottom: "10px" }}>
                        <TextField
                          fullWidth
                          disabled
                          autoComplete="off"
                          label="الرصيد"
                          value={balance}
                          onChange={(e) => setBalance(e.target.value)}
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <TextField
                          fullWidth
                          disabled
                          autoComplete="off"
                          label="الإجمالي"
                          value={total}
                          onChange={(e) => setTotal(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row style={{ marginTop: "30px" }}>
            {!id && (
              <Col
                xs={12}
                sm={4}
                md={4}
                style={{ marginBottom: "20px", textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  className="secondary-btn"
                  onClick={handleAddItem}
                  disabled={preventUpdate}
                  hidden={preventUpdate}
                >
                  إضافة إلى السلة
                </Button>
              </Col>
            )}

            {!id && (
              <Col
                xs={12}
                sm={4}
                md={4}
                style={{ marginBottom: "20px", textAlign: "center" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  className="secondary-btn"
                  hidden={preventUpdate}
                  disabled={preventUpdate}
                >
                  {id ? "تعديل الشحنة" : "تسجيل الشحنة"}
                </Button>
              </Col>
            )}

           {!id && <Col
              xs={12}
              sm={4}
              md={4}
              style={{ marginBottom: "20px", textAlign: "center" }}
            >
              <Button
                variant="contained"
                className="primary-btn"
                onClick={handleEmptyItems}
                disabled={preventUpdate}
                hidden={preventUpdate}
              >
                إفراغ السلة
              </Button>
            </Col>}

            <Col
              xs={12}
              sm={4}
              md={4}
              style={{ marginBottom: "20px", textAlign: "center" }}
            >
              <Button
                type="submit"
                variant="contained"
                className="primary-btn"
                onClick={() => navigate("/store-order")}
              >
                رجوع
              </Button>
            </Col>
          </Row>
          <StoreOrderDetailsGridView
            rows={orderItems}
            setRows={setOrderItems}
            preventUpdate={preventUpdate}
            id={id}
          />
        </>
      )}
    </form>
  );
};

export default StoreOrderDetailsPage;
