export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const ADD_ORDER_REQUEST = "ADD_ORDER_REQUEST";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const CHANGE_ORDER_SEARCH_TYPE = "CHANGE_ORDER_SEARCH_TYPE";

// orders and store orders summary
export const GET_MAIN_ORDERS_SUMMARY_REQUEST =
  "GET_MAIN_ORDERS_SUMMARY_REQUEST";
export const GET_MAIN_ORDERS_SUMMARY_SUCCESS =
  "GET_MAIN_ORDERS_SUMMARY_SUCCESS";
export const GET_MAIN_ORDERS_SUMMARY_FAIL = "GET_MAIN_ORDERS_SUMMARY_FAIL";

// delivery orders summary
export const GET_MAIN_ORDERS_DELIVERY_SUMMARY_REQUEST =
  "GET_MAIN_ORDERS_DELIVERY_SUMMARY_REQUEST";
export const GET_MAIN_ORDERS_DELIVERY_SUMMARY_SUCCESS =
  "GET_MAIN_ORDERS_DELIVERY_SUMMARY_SUCCESS";
export const GET_MAIN_ORDERS_DELIVERY_SUMMARY_FAIL =
  "GET_MAIN_ORDERS_DELIVERY_SUMMARY_FAIL";

// store orders
export const GET_STORE_ORDERS_REQUEST = "GET_STORE_ORDERS_REQUEST";
export const GET_STORE_ORDERS_SUCCESS = "GET_STORE_ORDERS_SUCCESS";
export const GET_STORE_ORDERS_FAIL = "GET_STORE_ORDERS_FAIL";

export const ADD_STORE_ORDER_REQUEST = "ADD_STORE_ORDER_REQUEST";
export const ADD_STORE_ORDER_SUCCESS = "ADD_STORE_ORDER_SUCCESS";
export const ADD_STORE_ORDER_FAIL = "ADD_STORE_ORDER_FAIL";

export const CHANGE_STORE_ORDER_SEARCH_TYPE = "CHANGE_STORE_ORDER_SEARCH_TYPE";

// storage
export const GET_STORAGES_REQUEST = "GET_STORAGES_REQUEST";
export const GET_STORAGES_SUCCESS = "GET_STORAGES_SUCCESS";
export const GET_STORAGES_FAIL = "GET_STORAGES_FAIL";

// // show room
// export const GET_SHOW_ROOM_ORDERS_REQUEST = "GET_SHOW_ROOM_ORDERS_REQUEST";
// export const GET_SHOW_ROOM_ORDERS_SUCCESS = "GET_SHOW_ROOM_ORDERS_SUCCESS";
// export const GET_SHOW_ROOM_ORDERS_FAIL = "GET_SHOW_ROOM_ORDERS_FAIL";

// delivery orders
export const GET_DELIVERY_ORDERS_REQUEST = "GET_DELIVERY_ORDERS_REQUEST";
export const GET_DELIVERY_ORDERS_SUCCESS = "GET_DELIVERY_ORDERS_SUCCESS";
export const GET_DELIVERY_ORDERS_FAIL = "GET_DELIVERY_ORDERS_FAIL";

export const UPDATE_DELIVERY_ORDER_REQUEST = "UPDATE_DELIVERY_ORDER_REQUEST";
export const UPDATE_DELIVERY_ORDER_SUCCESS = "UPDATE_DELIVERY_ORDER_SUCCESS";
export const UPDATE_DELIVERY_ORDER_FAIL = "UPDATE_DELIVERY_ORDER_FAIL";

export const CHANGE_DELIVERY_ORDER_SEARCH_TYPE =
"CHANGE_DELIVERY_ORDER_SEARCH_TYPE";

export const GET_RETURN_REASONS_REQUEST = "GET_RETURN_REASONS_REQUEST";
export const GET_RETURN_REASONS_SUCCESS = "GET_RETURN_REASONS_SUCCESS";
export const GET_RETURN_REASONS_FAIL = "GET_RETURN_REASONS_FAIL";

export const CHANGE_ORDER_CASE = "CHANGE_ORDER_CASE";
export const CLEAR_ORDER_CASE = "CLEAR_ORDER_CASE";

export const SET_DELIVER_ORDERS_SEARCH_TEXT = "SET_DELIVER_ORDERS_SEARCH_TEXT";

export const CONFIRM_ADD_ORDER_MESSAGE = "CONFIRM_ADD_ORDER_MESSAGE";
export const CONFIRM_ADD_STORE_ORDER_MESSAGE = "CONFIRM_ADD_STORE_ORDER_MESSAGE";

