import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  changeOrderCaseToConfirm,
  changeOrderCaseToReturning,
} from "../redux/actions/orderActions";

const DeliveryOrderCard = ({
  orderNumber,
  storeName,
  date,
  address,
  phone,
  amount,
  description,
  reasonText,
  reasonName,
}) => {
  const dispatch = useDispatch();

  const { type } = useSelector((state) => state.deliveryOrders);

  const handleReturnRequest = () => {
    dispatch(changeOrderCaseToReturning(orderNumber, storeName));
  };

  const handleConfirmRequest = () => {
    dispatch(changeOrderCaseToConfirm(orderNumber, storeName));
  };

  return (
    <div className="delivery-order-card">
      <div className="delivery-order-card-header">
        {type == "shipping" ? (
          <>
            <IconButton
              style={{ color: "var(--primary-color)" }}
              onClick={handleReturnRequest}
            >
              <ReplyAllIcon />
            </IconButton>
            <IconButton
              style={{ color: "green" }}
              onClick={handleConfirmRequest}
            >
              <TaskAltIcon />
            </IconButton>
          </>
        ) : type == "returned" ? (
          <div className="d-flex flex-row flex-wrap justify-content-start align-items-start w-100">
            <div className="flex-grow-1">
              <p
                style={{
                  width: "100%",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                {reasonName
                  ? "السبب: " + reasonName
                  : "السبب: " + "لم يتم تحديد سبب"}
              </p>
              <p
                style={{
                  width: "100%",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                {reasonText
                  ? "ملاحظة: " + reasonText
                  : "ملاحظة: " + "لم يتم كتابة ملاحظة"}
              </p>
            </div>
            <IconButton
              style={{ color: "green" }}
              onClick={handleConfirmRequest}
            >
              <TaskAltIcon />
            </IconButton>
          </div>
        ) : (
          <p>تم التسليم</p>
        )}
      </div>
      <div className="delivery-order-card-body">
        <div className="info">
          <h5 className="title">رقم الطلبية: </h5>
          <p>{orderNumber}</p>
        </div>
        <div className="info">
          <h5 className="title">تاريخ الطلبية: </h5>
          <p>{date}</p>
        </div>
        <div className="info">
          <h5 className="title">اسم المتجر: </h5>
          <p>{storeName}</p>
        </div>
        <div className="info">
          <h5 className="title">العنوان: </h5>
          <p>{address}</p>
        </div>
        <div className="info">
          <h5 className="title">رقم الهاتف: </h5>
          <a
            className="primary-link"
            href={`tel:${phone}`}
            style={{ cursor: "pointer" }}
          >
            {phone}
          </a>
        </div>
        <div className="info">
          <h5 className="title">القيمة: </h5>
          <p>{parseFloat(amount).toLocaleString("en-GB")} دينار</p>
        </div>
        <div className="info">
          <h5 className="title">وصف الشحنة: </h5>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default DeliveryOrderCard;
