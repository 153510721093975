import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
  TextField,
  Button,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import CustomDatePicker from "../components/CustomDatePicker";
import { getCities, getPlaces } from "../redux/actions/cityActions";
import {
  addOrder,
  changeOrderSearchType,
  updateOrder,
} from "../redux/actions/orderActions";

const OrderDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loginInfo } = useSelector((state) => state.userLogin);

  const { loadingCities, cities } = useSelector((state) => state.cities);

  const { loadingPlaces, places } = useSelector((state) => state.places);

  const { loadingAddOrder, loadingUpdateOrder, orders } = useSelector(
    (state) => state.orders
  );

  const [customerName, setCustomerName] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [city, setCity] = useState(null);
  const [place, setPlace] = useState(null);
  const [address, setAddress] = useState("");
  const [date, setDate] = useState(Date.now());
  const [time, setTime] = useState(Date.now());
  const [amount, setAmount] = useState("");
  const [pcsCount, setPcsCount] = useState("");
  const [description, setDescription] = useState("");
  const [transaction, setTransaction] = useState("0");
  const [deliveryPrice, setDeliveryPrice] = useState("0");

  const [preventUpdate, setPreventUpdate] = useState(false);

  const handlePhone1Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 10); // Limit to maximum length (adjust as needed)
    setPhone1(maxLengthValue);
  };

  const handlePhone2Change = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/[^0-9+]/g, ""); // Remove non-numeric and non-plus (+) characters
    const maxLengthValue = formattedValue.slice(0, 10); // Limit to maximum length (adjust as needed)
    setPhone2(maxLengthValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (preventUpdate) return;

    const shDate = new Date(date);
    const shDateStrnig = `${shDate.getFullYear()}-${String(
      shDate.getMonth() + 1
    ).padStart(2, "0")}-${String(shDate.getDate()).padStart(
      2,
      "0"
    )} 00:00:00.000`;

    if (id) {
      const orderData = {
        ID: id,
        CoName: customerName.toString(),
        Tel1: phone1,
        Tel2: phone2,
        Adress: address,
        CitiesID: city.ID,
        HalaID: deliveryPrice.toString(),
        RemoneID: transaction.toString(),
        wasf: description,
        Count: pcsCount,
        Bar1: place.ID.toString(),
        Amount: amount.toString(),
      };
      dispatch(updateOrder(orderData));
    } else {
      const orderData = {
        CoName: customerName.toString(),
        Tel1: phone1,
        Tel2: phone2,
        Adress: address,
        CitieID: city.ID,
        ShDate: shDateStrnig,
        StorID: loginInfo.BranchID.toString(),
        HalaID: deliveryPrice.toString(),
        RemoneID: transaction.toString(),
        wasf: description,
        Count: pcsCount,
        TimeIn: new Date().toLocaleTimeString("en-US", {
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        Bar1: place.ID.toString(),
        UserID: loginInfo.SerUser.toString(),
        Amount: amount.toString(),
      };
      dispatch(addOrder(orderData));
    }
    dispatch(changeOrderSearchType("all"));
    navigate("/orders");
  };

  const handleClearData = () => {
    setCustomerName("");
    setPhone1("");
    setPhone2("");
    setAddress("");
    setAmount("");
    setPcsCount("");
    setDescription("");
    setTransaction(0);
    setDeliveryPrice(0);
    setPlace(null);
    setCity(null);
    setDate(Date.now());
    setPreventUpdate(false);
  };

  useEffect(() => {
    if ((cities.length > 0) & (places.length > 0))
      if (id) {
        const order = orders.find((item) => item.ID == id);
        if (order) {
          setCustomerName(order.CoName);
          setPhone1(order.Tel1);
          setPhone2(order.Tel2);
          setAddress(order.Adress);
          setCity(cities.find((city) => city.CName === order.CName));
          setDate(order.ShDate.date);
          setTransaction(order.RemoneID);
          setDeliveryPrice(order.HalaID);
          setDescription(order.wasf);
          setPlace(places.find((p) => p.ID == order.Bar1));
          setAmount(order.Amount);
          setPcsCount(order.Count);

          order && order.ShCase !== 1
            ? setPreventUpdate(true)
            : setPreventUpdate(false);
        } else navigate("/order-details");
      } else handleClearData();
  }, [cities, places]);

  useEffect(() => {
    dispatch(getCities());
    dispatch(getPlaces());

    return () => {
      handleClearData();
    };
  }, []);

  return (
    <form onSubmit={handleSubmit} className="order-details-page">
      {loadingCities | loadingPlaces ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <>
          <Row>
            <Col
              sm={12}
              md={6}
              style={{ marginBottom: "30px", height: "100%" }}
            >
              <div className="order-details-data-container">
                <h4>بيانات الزبون</h4>
                <div className="order-details-customer">
                  <TextField
                    required
                    fullWidth
                    autoComplete="off"
                    className="order-details-input"
                    label="اسم المستلم"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    disabled={preventUpdate}
                  />
                  <TextField
                    required
                    autoComplete="off"
                    className="order-details-input"
                    label="رقم الهاتف 1"
                    placeholder="92xxxxxxx"
                    type="text"
                    value={phone1}
                    onChange={handlePhone1Change}
                    disabled={preventUpdate}
                  />
                  <TextField
                    autoComplete="off"
                    className="order-details-input"
                    label="رقم الهاتف 2"
                    type="text"
                    placeholder="92xxxxxxx"
                    value={phone2}
                    onChange={handlePhone2Change}
                    disabled={preventUpdate}
                  />
                  <Autocomplete
                    disablePortal
                    options={cities}
                    getOptionLabel={(option) => option.CName}
                    value={city}
                    onChange={(e, newValue) => setCity(newValue)}
                    className="order-details-input"
                    disabled={preventUpdate}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        className="order-details-input"
                        {...params}
                        label="المدينة"
                      />
                    )}
                  />
                  <TextField
                    required
                    fullWidth
                    autoComplete="off"
                    className="order-details-input"
                    label="العنوان"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    disabled={preventUpdate}
                  />
                  <CustomDatePicker
                    label={"تاريخ الشحنة"}
                    style={{ width: "100%" }}
                    containerStyle={{ width: "100%", maxWidth: "400px" }}
                    value={date}
                    setValue={setDate}
                    disabled={preventUpdate}
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="order-details-data-container">
                <h4>بيانات الشحنة</h4>
                <div className="order-details-shipment">
                  <Autocomplete
                    disablePortal
                    options={places}
                    getOptionLabel={(option) => option.BName}
                    value={place}
                    onChange={(e, newValue) => setPlace(newValue)}
                    className="order-details-input"
                    disabled={preventUpdate}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        className="order-details-input"
                        {...params}
                        label="مكان الإستلام"
                      />
                    )}
                  />
                  <TextField
                    required
                    fullWidth
                    type="number"
                    autoComplete="off"
                    className="order-details-input"
                    label="قيمة الشحنة"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    disabled={preventUpdate}
                  />
                  <TextField
                    required
                    fullWidth
                    type="number"
                    autoComplete="off"
                    className="order-details-input"
                    label="عدد القطع"
                    value={pcsCount}
                    onChange={(e) => setPcsCount(e.target.value)}
                    disabled={preventUpdate}
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    className="order-details-input"
                    label="وصف الشحنة"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    disabled={preventUpdate}
                  />
                  <FormControl>
                    <FormLabel>سداد قيمة التوصيل</FormLabel>
                    <RadioGroup
                      row
                      name="delivery-groub"
                      value={deliveryPrice}
                      onChange={(e) => setDeliveryPrice(e.target.value)}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio disabled={preventUpdate} />}
                        label="المستلم"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio disabled={preventUpdate} />}
                        label="المرسل"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>سداد قيمة الحوالة</FormLabel>
                    <RadioGroup
                      row
                      name="transaction-groub"
                      value={transaction}
                      onChange={(e) => setTransaction(e.target.value)}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio disabled={preventUpdate} />}
                        label="المستلم"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio disabled={preventUpdate} />}
                        label="المرسل"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </Col>
          </Row>
          <div className="order-details-btn-container">
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "var(--secondary-color)",
                color: "var(--secondary-text-color)",
              }}
              endIcon={
                loadingAddOrder ? (
                  <CircularProgress />
                ) : (
                  loadingUpdateOrder && <CircularProgress />
                )
              }
              disabled={preventUpdate}
              hidden={preventUpdate}
            >
              {id ? "تعديل الشحنة" : "إضافة شحنة"}
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "var(--primary-color)" }}
              onClick={() => navigate(-1)}
            >
              رجوع
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default OrderDetailsPage;
