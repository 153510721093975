import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import {
  changeStoreOrderSearchType,
  getMainOrdersSummary,
} from "../redux/actions/orderActions";
import PageCard from "../components/PageCard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

const CompanyStorePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.userLogin);

  const { loadingMainOrdersSummary, mainOrdersSummary } = useSelector(
    (state) => state.mainOrdersSummary
  );

  const openStoreOrders = (type) => {
    dispatch(changeStoreOrderSearchType(type));
    navigate("/store-order");
  };

  useEffect(() => {
    dispatch(getMainOrdersSummary(loginInfo.BranchID));
  }, []);

  return (
    <div className="d-flex flex-column vh-100">
      {loadingMainOrdersSummary ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <>
          <Row style={{ margin: "10px auto" }}>
            <Col xs={12} sm={6} md={6} className="pb-2">
              <div
                className="d-flex flex-row p-2 my-2 mx-auto align-items-center"
                style={{
                  borderRadius: "5px",
                  justifyContent: "space-between",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                <div className="d-flex flex-column">
                  <h5 style={{ color: "white" }}>إجمالي الطلبيات</h5>
                  <strong
                    style={{
                      fontSize: 18,
                      marginRight: "10px",
                      color: "white",
                    }}
                  >
                    {mainOrdersSummary
                      ? mainOrdersSummary.kind2[0].Total_k2 || 0
                      : 0}
                  </strong>
                </div>
                <EqualizerIcon style={{ fontSize: "40px", color: "white" }} />
              </div>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <div
                className="d-flex flex-row p-2 my-2 mx-auto align-items-center"
                style={{
                  borderRadius: "5px",
                  justifyContent: "space-between",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                <div className="d-flex flex-column">
                  <h5 style={{ color: "white" }}>المحفظة</h5>
                  <strong
                    style={{
                      fontSize: 18,
                      marginRight: "10px",
                      color: "white",
                    }}
                  >
                    {mainOrdersSummary
                      ? mainOrdersSummary.kind1[0].almahfaza
                        ? parseFloat(
                            mainOrdersSummary.kind1[0].almahfaza || 0
                          ).toLocaleString("en-GB")
                        : 0
                      : 0}
                  </strong>
                </div>
                <AttachMoneyIcon style={{ fontSize: "40px", color: "white" }} />
              </div>
            </Col>
          </Row>
          <Row style={{ margin: "10px auto" }}>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"اليوم"}
                number={
                  mainOrdersSummary
                    ? mainOrdersSummary.kind2[0]?.Count_todayk2 || 0
                    : 0
                }
                image={
                  <AccessTimeIcon
                    style={{ fontSize: "40px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => openStoreOrders("today")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"تحت الإجراء"}
                number={
                  mainOrdersSummary
                    ? mainOrdersSummary.kind2[0]?.Count_case1k2 || 0
                    : 0
                }
                image={
                  <ManageHistoryIcon
                    style={{ fontSize: "40px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => openStoreOrders("proccessing")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"قيد الشحن"}
                number={
                  mainOrdersSummary
                    ? mainOrdersSummary.kind2[0]?.Count_case3k2 || 0
                    : 0
                }
                image={
                  <LocalShippingIcon
                    style={{ fontSize: "40px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => openStoreOrders("shipping")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"تم تسليمها"}
                number={
                  mainOrdersSummary
                    ? mainOrdersSummary.kind2[0]?.Count_case4k2 || 0
                    : 0
                }
                image={
                  <ShoppingBagIcon
                    style={{ fontSize: "40px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => openStoreOrders("delivered")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"راجع"}
                number={
                  mainOrdersSummary
                    ? mainOrdersSummary.kind2[0]?.Count_case7k2 || 0
                    : 0
                }
                image={
                  <ReplyAllIcon
                    style={{ fontSize: "40px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => openStoreOrders("returnedBack")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"تم الإسترداد"}
                number={
                  mainOrdersSummary
                    ? mainOrdersSummary.kind2[0].Count_case10k2 || 0
                    : 0
                }
                image={
                  <SettingsBackupRestoreIcon
                    style={{ fontSize: "60px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => openStoreOrders("returned")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"تم تسويتها"}
                number={
                  mainOrdersSummary
                    ? mainOrdersSummary.kind2[0]?.Count_case5k2 || 0
                    : 0
                }
                image={
                  <TaskAltIcon
                    style={{ fontSize: "40px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => openStoreOrders("done")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"مبيعات Show Room"}
                number={
                  mainOrdersSummary ? mainOrdersSummary.kind1[0]?.showroom : 0
                }
                image={
                  <AttachMoneyIcon
                    style={{ fontSize: "40px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => {
                  dispatch(changeStoreOrderSearchType("show-room"));
                  navigate("/show-room-orders");
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CompanyStorePage;
