import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

//icons
import HomeIcon from "@mui/icons-material/Home";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PolicyIcon from "@mui/icons-material/Policy";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PlaceIcon from "@mui/icons-material/Place";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MarginIcon from "@mui/icons-material/Margin";
import StoreIcon from "@mui/icons-material/Store";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import {
  changeOrderSearchType,
  changeStoreOrderSearchType,
} from "../redux/actions/orderActions";

const NavDrawer = ({ open, setOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.userLogin);

  const navigeToPage = (path) => {
    toggleDrawer();
    navigate(path);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const list = () => (
    <Box style={{ width: 250 }} role="presentation">
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigeToPage("/")}>
            <ListItemIcon
              style={{
                color:
                  location.pathname === "/"
                    ? "var(--primary-color)"
                    : "var(--primary-text-color)",
              }}
            >
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"الرئيسية"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigeToPage("/order-details")}>
            <ListItemIcon
              style={{
                color:
                  location.pathname === "/order-details"
                    ? "var(--primary-color)"
                    : "var(--primary-text-color)",
              }}
            >
              <AddCircleIcon />
            </ListItemIcon>
            <ListItemText primary={"إضافة طلبية"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              dispatch(changeOrderSearchType("all"));
              navigeToPage("/orders");
            }}
          >
            <ListItemIcon
              style={{
                color:
                  location.pathname === "/orders"
                    ? "var(--primary-color)"
                    : "var(--primary-text-color)",
              }}
            >
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText primary={"عرض الطلبيات"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigeToPage("/settlements")}>
            <ListItemIcon
              style={{
                color:
                  location.pathname === "/settlements"
                    ? "var(--primary-color)"
                    : "var(--primary-text-color)",
              }}
            >
              <PointOfSaleIcon />
            </ListItemIcon>
            <ListItemText primary={"التسويات"} />
          </ListItemButton>
        </ListItem>
        <div
          style={{
            backgroundColor: "var(--primary-color)",
            height: "1px",
            width: "90%",
            margin: "1px auto",
          }}
        />
        {loginInfo.TyepContr && (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigeToPage("/company-store")}>
                <ListItemIcon
                  style={{
                    color:
                      location.pathname === "/company-store"
                        ? "var(--primary-color)"
                        : "var(--primary-text-color)",
                  }}
                >
                  <MarginIcon />
                </ListItemIcon>
                <ListItemText primary={"إدارة المساحة التخزينية"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  dispatch(changeStoreOrderSearchType("all"));
                  navigeToPage("/store-order-details");
                }}
              >
                <ListItemIcon
                  style={{
                    color:
                      location.pathname === "/store-order-details"
                        ? "var(--primary-color)"
                        : "var(--primary-text-color)",
                  }}
                >
                  <AddCircleIcon />
                </ListItemIcon>
                <ListItemText primary={"إضافة طلبية مخزن"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  dispatch(changeStoreOrderSearchType("all"));
                  navigeToPage("/store-order");
                }}
              >
                <ListItemIcon
                  style={{
                    color:
                      location.pathname === "/store-order"
                        ? "var(--primary-color)"
                        : "var(--primary-text-color)",
                  }}
                >
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText primary={"عرض طلبيات المخزن"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigeToPage("/item-movement")}>
                <ListItemIcon
                  style={{
                    color:
                      location.pathname === "/item-movement"
                        ? "var(--primary-color)"
                        : "var(--primary-text-color)",
                  }}
                >
                  <TimelineOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={"حركة صنف"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigeToPage("/inventory")}>
                <ListItemIcon
                  style={{
                    color:
                      location.pathname === "/inventory"
                        ? "var(--primary-color)"
                        : "var(--primary-text-color)",
                  }}
                >
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={"جرد المخزن"} />
              </ListItemButton>
            </ListItem>
            <div
              style={{
                backgroundColor: "var(--primary-color)",
                height: "1px",
                width: "90%",
                margin: "1px auto",
              }}
            />
          </>
        )}

        <ListItem disablePadding>
          <ListItemButton onClick={() => window.open("/policy.html", "_blank")}>
            <ListItemIcon
              style={{
                color:
                  location.pathname === "/shipping-policy"
                    ? "var(--primary-color)"
                    : "var(--primary-text-color)",
              }}
            >
              <PolicyIcon />
            </ListItemIcon>
            <ListItemText primary={"سياسة الشحن"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => window.open("/agreement.html", "_blank")}
          >
            <ListItemIcon
              style={{
                color:
                  location.pathname === "/user-agreement"
                    ? "var(--primary-color)"
                    : "var(--primary-text-color)",
              }}
            >
              <HandshakeIcon />
            </ListItemIcon>
            <ListItemText primary={"إتفاقية المستخدم"} />
          </ListItemButton>
        </ListItem>
        <div
          style={{
            backgroundColor: "var(--primary-color)",
            height: "1px",
            width: "90%",
            margin: "1px auto",
          }}
        />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => window.open("https://wa.me/+218927716601", "_blank")}
          >
            <ListItemIcon style={{ color: "#25D366" }}>
              <WhatsAppIcon />
            </ListItemIcon>
            <ListItemText primary={"خدمة العملاء"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box>
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        {list()}
      </Drawer>
    </Box>
  );
};

export default NavDrawer;
