import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import PageCard from "../components/PageCard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import {
  changeDeliveryOrderSearchType,
  getDeliveryOrdersSummary,
} from "../redux/actions/orderActions";

const DeliveryMainPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.userLogin);
  const { loadingDeliveryOrdersSummary, deliveryOrdersSummary } = useSelector(
    (state) => state.deliveryOrdersSummary
  );
  const { loadingDeliverySettlements, deliverySettlements } = useSelector(
    (state) => state.deliverySettlements
  );

  const handleOpenOrdersPage = (type) => {
    dispatch(changeDeliveryOrderSearchType(type));
    navigate("/orders");
  };

  useEffect(() => {
    dispatch(getDeliveryOrdersSummary(loginInfo.BranchID));
  }, []);

  return (
    <div className="main-page">
      {loadingDeliveryOrdersSummary | loadingDeliverySettlements ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <>
          <Row style={{ margin: "10px auto" }}>
            <Col xs={12} sm={6} md={6} className="pb-2">
              <div
                className="d-flex flex-row p-2 my-2 mx-auto align-items-center"
                style={{
                  borderRadius: "5px",
                  justifyContent: "space-between",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                <div className="d-flex flex-column">
                  <h5 style={{ color: "white" }}>الإجمالي</h5>
                  <strong
                    style={{
                      fontSize: 18,
                      marginRight: "10px",
                      color: "white",
                    }}
                  >
                    {deliveryOrdersSummary
                      ? parseFloat(
                          deliveryOrdersSummary.Total || 0
                        ).toLocaleString("en-GB")
                      : 0}
                  </strong>
                </div>
                <EqualizerIcon style={{ fontSize: "40px", color: "white" }} />
              </div>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <div
                className="d-flex flex-row p-2 my-2 mx-auto align-items-center"
                style={{
                  borderRadius: "5px",
                  justifyContent: "space-between",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                <div className="d-flex flex-column">
                  <h5 style={{ color: "white" }}>النسبة</h5>
                  <strong
                    style={{
                      fontSize: 18,
                      marginRight: "10px",
                      color: "white",
                    }}
                  >
                    {deliveryOrdersSummary
                      ? parseFloat(
                          deliveryOrdersSummary.DelgAmount || 0
                        ).toLocaleString("en-GB")
                      : 0}
                  </strong>
                </div>
                <PercentIcon style={{ fontSize: "40px", color: "white" }} />
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} className="pb-2">
              <div
                className="d-flex flex-row p-2 my-2 mx-auto align-items-center"
                style={{
                  borderRadius: "5px",
                  justifyContent: "space-between",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                <div className="d-flex flex-column">
                  <h5 style={{ color: "white" }}>المحفظة</h5>
                  <strong
                    style={{
                      fontSize: 18,
                      marginRight: "10px",
                      color: "white",
                    }}
                  >
                    {deliveryOrdersSummary
                      ? parseFloat(
                          deliveryOrdersSummary.almahfaza || 0
                        ).toLocaleString("en-GB")
                      : 0}
                  </strong>
                </div>
                <AttachMoneyIcon style={{ fontSize: "40px", color: "white" }} />
              </div>
            </Col>
          </Row>
          <Row style={{ margin: "10px auto" }}>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"طلبيات قيد الشحن"}
                number={
                  deliveryOrdersSummary ? deliveryOrdersSummary.Count_case3 : 0
                }
                image={
                  <LocalShippingIcon
                    style={{ fontSize: "60px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => handleOpenOrdersPage("shipping")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"طلبيات تم إرجاعها"}
                number={
                  deliveryOrdersSummary ? deliveryOrdersSummary.Count_case7 : 0
                }
                image={
                  <ReplyAllIcon
                    style={{ fontSize: "60px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => handleOpenOrdersPage("returned")}
              />
            </Col>
            <Col sm={12} md={6} lg={6} xxl={3}>
              <PageCard
                name={"طلبيات تم التسليم"}
                number={
                  deliveryOrdersSummary ? deliveryOrdersSummary.Count_case4 : 0
                }
                image={
                  <ShoppingBagIcon
                    style={{ fontSize: "60px", color: "var(--primary-color)" }}
                  />
                }
                handleClick={() => handleOpenOrdersPage("done")}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default DeliveryMainPage;
